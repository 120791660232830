import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";

export default function Business() {
  return (
    <div>
      <div className="sticky-wrap" style={{ minHeight: "74px" }}>
        <Header />
      </div>
      <div
        className="title"
        style={{ textAlign: "center", fontSize: 30, marginBottom: "20px" }}
      >
        업무위수탁약관
      </div>
      <Navigation />
      <div style={{ width: "100%" }}>
        <div>
          <div id="agreement-section-1">
            <div className="agreement-section-in">
              <h2>제1조 (목적)</h2>
              <p className="mgbotm40">
                본 약관은 ㈜불타는고구마(이하 "회사")이 헬퍼잇 서비스 이용약관
                제2조에 정의된 헬퍼(이하 "수탁인")에게 본 약관 제3조 기재 업무를
                위탁함에 있어서 회사와 수탁인의 권리와 의무에 관한 사항을 정함을
                그 목적으로 합니다.
              </p>
              <h2>제2조 (수탁인의 지위)</h2>
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span>수탁인은 회사에 종속되지
                  아니하고 독립적으로 위탁업무를 수행하는 거래주체입니다.
                </li>
                <li>
                  <span className="txt_num">2.</span>수탁인에 대하여서는 회사의
                  취업규칙 등 사규 일체가 적용되지 아니합니다.
                </li>
                <li>
                  <span className="txt_num">3.</span>수탁인은 위탁업무의 수행과
                  관련하여 회사로부터 구체적・개별적 지휘·감독을 받지
                  아니합니다.
                </li>
              </ul>
              <h2>제3조 (위탁업무)</h2>
              회사가 수탁인에게 위탁하는 업무(이하 "위탁업무")는 다음 각호와
              같습니다.
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span>"헬퍼잇-심부름"(이하
                  "서비스")고객이 요청하는 업무(이하 "심부름")의 수행
                </li>
                <li>
                  <span className="txt_num">2.</span>제1호 업무에 직접 또는
                  간접적으로 부수하는 업무 수탁인은 위탁업무의 구체적인 내용 및
                  수행 방안 등을 스스로 정하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">3.</span>수탁인은 위탁업무의 수행에
                  소요되는 비용을 스스로 마련하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">4.</span>수탁인은 자유로운 의사에
                  따라 위탁업무 수행 여부를 결정할 수 있습니다. 다만, 수행하기로
                  결정한 업무의 경우, 업무 수행 중 수탁인의 요청으로 위탁업무를
                  취소할 경우 헬퍼잇 서비스 이용약관 제11조 제3항에 따라
                  수탁인의 위탁업무 수행활동이 30일간 정지되며 2회 이상 하실
                  경우 영구 탈퇴 처리 됩니다.
                </li>
                <li>
                  <span className="txt_num">5.</span>
                  수탁인은 위탁 업무 수행 시 고객의 연락 불이행 문제로 업무를
                  수행할 수 없을 경우, 최소 30분간 수탁 업무를 중지하지 않아야
                  할 의무가 있습니다. 수탁인은 해당 상황이 발생할 경우 회사에
                  알려야 하며, 회사는 수탁인과 고객의 연락을 중개하도록
                  노력하겠습니다.
                </li>
                <li>
                  <span className="txt_num">6.</span>회사는 수탁인과 고객 간
                  매칭을 도와드리는 중개 플랫폼으로서, 인터넷 상의 공간만 제공할
                  뿐, 기본적으로 당사자 간의 거래 여부 및 거래 내용에 일절
                  관여하지 않습니다.
                </li>
                <li>
                  <span className="txt_num">7.</span>개별 거래에서 저작권,
                  소유권, 사용권 등 법적 권리에 대해서는 이용자들이 개별적으로
                  정할 수 있고, 이에 관하여 회사는 어떠한 책임도 부담하지
                  않습니다.
                </li>
                <li>
                  <span className="txt_num">8.</span>
                  또한 개별 이용자들 간 연락, 방문 과정에서 일어나는 폭행, 상해,
                  성범죄, 사기 등 민, 형사상 책임에 대해서 개별 이용자들이
                  전적으로 책임을 부담하며, 회사는 책임을 부담하지 않습니다.
                </li>
                <li>
                  <span className="txt_num">8.</span>
                  만일 이로 인해 회사가 피해 이용자로부터 민, 형사 소송 등 기타
                  책임을 추궁당하는 경우, 가해 이용자가 회사를 적극적으로
                  면책시켜야 하며, 만일 회사가 피해를 입는 경우, 가해 이용자에게
                  민, 형사 법적 책임을 물을 수 있습니다.
                </li>
              </ul>
              <h2>제4조 (약관 적용 기간)</h2>
              <p className="mgbotm40">
                본 약관의 적용기간은 본 약관에 동의한 날로부터 수탁인이 서비스
                계정을 탈퇴할 때까지로 합니다.
              </p>
              <h2>제5조 (수익금의 지급)</h2>
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span> 회사는 수탁인의 위탁업무
                  수행에 대한 대가로 위탁보수 ( 이하 '수익금' ) 를 지급하며, 이
                  때의 수익금은 ‘헬퍼잇’ 서비스 요청 고객이 정한 금액으로
                  합니다.
                </li>
                <li>
                  <span className="txt_num">2.</span>회사는 수탁인의 인출신청에
                  따라 수탁인이 지정한 계좌로 수익금을 지급합니다.
                </li>
                <li>
                  <span className="txt_num">3.</span>수탁인은 위 지급일로부터
                  2일 전까지 인출 신청을 하여야 합니다. 단, 수탁인이 적립
                  수익금이 10,000원 이상인 경우에 한하여 인출 신청할 수
                  있습니다. 또한, 근로 기준법 제43조 ‘임금은 직접 근로자에게 그
                  전액을 지급하여야 한다’에 의거하여 본인 명의가 아닌 가족 혹은
                  타 명의 계좌를 등록하셨다면 수익금 지급이 자동 보류됩니다.
                </li>
                <li>
                  <span className="txt_num">4.</span>적립 수익금의 금액과
                  관계없이 회사를 통하지 않고 직거래로 위탁업무 진행 및 수익금을
                  지급받을 경우, 이를 위해 개인 연락처를 공유할 경우, 활동이
                  영구 정지되며, 고객과 발생하는 모든 분쟁에 대해 회사가 중재 및
                  책임지지 않습니다. 또한 [업무 위수탁 약관] 5조 2항에 의거하여
                  적립된 수익금은 모두 회사에 귀속되며, 회사는 수탁인에 대하여
                  손해배상 청구를 할 수 있습니다.
                </li>
                <li>
                  <span className="txt_num">5.</span>
                  회사 수수료 10%, 결제망 이용료 2.2%, 출금 수수료 1.5%,
                  원천징수 세금 3.3%를 제외 하여 수익금으로 정산됩니다. 수탁인이
                  수행한 적립된 수익금을 보장하며 수탁인이 지정한 계좌로
                  수익금을 지급합니다. 다만, 헬퍼잇은 물건값,음식값 등에 한하여
                  위탁인과 직접 대면이체 , 수행후 계좌이체, 수행전 계좌이체
                  진행하기떄문에 3.3% 세금을 공제 하지 않습니다. 수탁인은 인출금
                  요청시 20만원 이상 인경우 2%, 50만 이상 인경우 4%
                  100만원이상인경우 10% 추가 보너스를 지급합니다.
                </li>
                <li>
                  <span className="txt_num">6.</span>회사는 서비스 이용에 대한
                  대가로서 수수료를 징수할 뿐, 고객과 수탁인 사이의 개별 거래에
                  관여하는 것은 아니며, 수익금 지급 업무를 처리한다고 하여도,
                  개별 거래에 대한 고객과 수탁인의 법적 책임을 대신 부담하지
                  않습니다.
                </li>
                <li>
                  <span className="txt_num">7.</span> 물건값, 음식값등
                  구매비용을 심부름 가격에 포함하여 심부름을 수행 한 경우에도
                  제5조 (수익금의 지급)5항에 따라 세금 정산된 금액을 인출
                  받습니다.
                </li>
              </ul>
              <h2>제6조 (정보 및 자료의 제공 등)</h2>
              회사는 위탁 업무와 관련하여 수탁인에게 필요한 정보와 자료를 제공
              할 수 있습니다.
              <br />
              <br />
              회사는 다음 각 호의 경우 수탁인에게 수탁인의 비용으로 위탁업무의
              수행에 직접적으로 또는 간접적으로 관련하는 교육의 참가를 요구할 수
              있습니다.
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span>
                  수탁인의 업무 수행을 위하여 수탁인의 교육이 필수적인 경우
                </li>
              </ul>
              <h2>제7조 (수탁인의 의무)</h2>
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span>
                  수탁인은 위탁업무 수행과 관련한 관계 법령, 조례, 행정규칙 등을
                  준수하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">2.</span>
                  수탁인은 위탁업무와 관련하여 회사와 고객 등 제3자 간에 분쟁이
                  발생한 사실 또는 발생할 가능성을 인식하는 경우 그 사실을
                  48시간 이내에 회사에 통지하여야 합니다.
                </li>
                <li>
                  <span className="txt_num">3.</span>
                  수탁인은 위탁업무 외의 목적으로 회사의 상호를 사용하거나
                  회사의 대리점, 임원 또는 직원으로서의 자격을 사칭하여서는
                  아니됩니다.
                </li>
                <li>
                  <span className="txt_num">4.</span>
                  수탁인은 회사가 요청하는 경우 위탁업무의 수행으로 취득하는
                  서류, 물건 등 유・무형자산 일체를 지체없이 회사에 전달하여야
                  합니다.
                </li>
                <li>
                  <span className="txt_num">5.</span>
                  수탁인은 위탁업무의 수행과 관련하여 지득한 회사의 상품・서비스
                  기획, 구성, 가격책정 등에 관한 정보, 기타 영업비밀을
                  침해하여서는 아니됩니다.
                </li>
                <li>
                  <span className="txt_num">6.</span>
                  수탁인은 회사의 사전 동의 없이는 위탁업무를 제3자에게 재위탁
                  할 수 없습니다.
                </li>
                <li>
                  <span className="txt_num">7.</span>
                  심부름 수행 후 48시간이 지나도록 고객으로부터 물품비 또는
                  음식비를 이체 받지 못한 경우, 해당 고객을 수사기관에 신고해야
                  합니다.
                </li>
                <li>
                  <span className="txt_num">8.</span>
                  회사는 수사기관의 정보 공개 공문 접수 후 수사기관에 해당
                  고객의 개인정보를 공개할 의무가 있습니다.수탁인은 주류·담배
                  배달, 약 배달, 대리처방, 카풀, 애인대행, 미행, 돈 빌리기,
                  추천인을 금전으로 사고파는 행위 등 불건전하거나 불법적인
                  심부름을 수행해서는 안 되며, 해당 심부름 요청서의 우측 상단
                  ‘사이렌’ 아이콘을 클릭하여 즉각 회사에 신고해야 합니다.
                </li>
              </ul>
              <h2>제8조 (위수탁의 해지)</h2>
              회사 또는 수탁인은 본 계약기간 중 언제든지 문자메세지/SNS 등에
              의한 통지로서 본 위수탁 계약을 해지할 수 있습니다. 이 경우 그
              해지통지가 상대방에게 도달한 때 즉시 해지됩니다.
              <br />
              <br />
              회사 또는 수탁인은 다음 각호의 어느 하나의 경우 통지없이 본 위수탁
              계약을 해지할 수 있습니다. 이 경우 즉시 본 계약이 해지됩니다.
              <ul className="agree_ul mgbotm40">
                <li>
                  <span className="txt_num">1.</span>본 약관의 동의 및 이에 따른
                  위수탁 업무의 이행 등과 관련하여 관계법령을 위반한 경우
                </li>
                <li>
                  <span className="txt_num">2.</span>계정생성 및 헬퍼,전문가
                  지원시 상대방에게 제공한 정보, 자료 등이 위조・변조되거나
                  허위로 작성된 경우
                </li>
                <li>
                  <span className="txt_num">3.</span>고의 또는 중대한 과실로 본
                  약관을 위반하는 경우
                </li>
                <li>
                  <span className="txt_num">4.</span>고의 또는 중대한 과실로
                  분쟁이 야기되어 제3자가 회사 또는 수탁인에게 민사상 소의 제기,
                  가압류・ 가처분신청, 형사상 고소・고발 등이 제기되는 경우
                </li>
                <li>
                  <span className="txt_num">5.</span>고객에게 통보 없이 서비스를
                  미수행하거나, 연락이 두절될 경우
                </li>
                <li>
                  <span className="txt_num">6.</span>심부름 시작 후 수행하지
                  않거나 취소할 경우, 또는 고객에게 대신 취소를 요청할 경우
                </li>
                <li>
                  <span className="txt_num">7.</span>[지금 즉시] 심부름을
                  시작(수락) 하고 10분 내로 수행하지 않을 경우
                </li>
                <li>
                  <span className="txt_num">8.</span>심부름비를 고객에게 추가로
                  요구하거나 앱을 통하지 않고 따로 지급받을 경우
                </li>
                <li>
                  <span className="txt_num">9.</span>게시물 또는 채팅에 휴대폰
                  번호, SNS ID, 메신저 ID 등을 기재하여, 어플을 통하지 않고
                  심부름비를 지급 받고자하는 경우
                </li>
                <li>
                  <span className="txt_num">10.</span>고객의 휴대폰 번호를
                  저장하여 개인적으로 메신저 또는 문자, 전화로 연락을 주고 받은
                  경우
                </li>
                <li>
                  <span className="txt_num">11.</span>고객 또는 ‘헬퍼잇’
                  상담원에게 반말, 욕설 등 부적절한 언어를 사용할 경우
                </li>
                <li>
                  <span className="txt_num">12.</span>기타 상호간 신뢰관계를
                  손상시킬 수 있는 사유가 발생하였다고 회사가 판단한 경우
                </li>
              </ul>
              <h2>제9조 (손해배상)</h2>
              <p className="mgbotm40">
                회사 또는 수탁인은 본 계약을 위반하여 상대방에게 손해를 가한
                경우 그 손해를 배상하여야 합니다.
              </p>
              <h2>제10조 (책임보험 등)</h2>
              <p className="mgbotm40">
                회사는 수탁인이 위탁업무와 관련하여 제3자에게 가할 수 있는
                불법행위로 인하여 회사가 부담할 수 있는 손해배상책임을 면책하기
                위하여 책임보험 가입 등을 요구할 수 있습니다.
              </p>
              <ul className="agree_ul mgbotm40">
                <li className="pd0">공고일자: 2023년 12월 14일</li>
                <li className="pd0">시행일자: 2023년 12월 21일</li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
