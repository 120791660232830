import React from "react";
import logo from "./../assets/img/logo.svg";
import mail from "./../assets/img/mail.svg";
import facebook from "./../assets/img/facebook.svg";
import instagram from "./../assets/img/instagram.svg";
import youtube from "./../assets/img/youtube.svg";
import kakao from "./../assets/img/kakao.svg";

export default function Footer() {
  return (
    <div id="footer">
      <div className="footer-in">
        <div className="section-2">
          <div>
            <p className="f-logo">
              <img src={logo} width="70%" alt="헬퍼잇 로고" />
            </p>
          </div>
          <div className="futill">
            <p>
              <a href="/service">이용약관</a>
            </p>
            <p>
              <a href="/policy">개인정보처리방침</a>
            </p>
            <ul className="fnb">
              <li>
                상호 : <span>주식회사 불타는고구마</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;대표이사 : <span>최석현</span>
                <br />
                개인정보관리책임자 : <span>박창현</span>
                <br />
                제휴문의 : <span>csh@bgogooma.com</span>
              </li>
              <li>
                사업자등록번호 : <span>803-87-02223</span>
                <br />
                통신판매 : <span> 제 2021-부산수영-0894 호</span>
              </li>
              <br />
              <li>
                <span>부산광역시 해운대구 삼어로 207-1 3층 </span>
                <br />
                심부름 문의 / 헬퍼파트너 문의 : <span>010-6660-0234</span>
                <br />
                대표전화번호 : <span>1800-9323</span>
              </li>
            </ul>
            <small className="fcopy">
              Copyright ⓒ 2023 (주)불타는 고구마 All Rights Reserved.
            </small>
          </div>
          <div className="fapp">
            <p>
              <a href="mailto:csh@bgogooma.com" className="mail">
                <img src={mail} alt="이메일" />
              </a>
            </p>
            <p>
              <a href="/">
                <img src={facebook} alt="페이스북" />
              </a>
            </p>
            <p>
              <a
                href="https://www.instagram.com/p/CToV6ZkhRwT/?utm_medium=copy_link"
                className="instagram"
              >
                <img src={instagram} alt="인스타그램" />
              </a>
            </p>
            <p>
              <a href="/">
                <img src={youtube} alt="유튜브" />
              </a>
            </p>
            <a href="https://pf.kakao.com/_wnGxos" className="kakao">
              <img src={kakao} alt="카카오" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
